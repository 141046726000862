




























































import { Component, Vue } from "vue-property-decorator";
import Big from "bignumber.js";

@Component({})
export default class Home extends Vue {
  komitmen = 200000;
  apr = 10;
  yearly = false;

  rows: {
    bulan: number;
    bunga: string;
    uang: string;
    pokok: string;
    normal: string;
    selisih: string;
  }[] = [];

  compute() {
    this.rows = [];
    let sekarang = new Big(this.komitmen);
    const bunga = new Big(this.apr).div(100).div(12);

    for (let i = 0; i < 240; i++) {
      const interest = bunga.times(sekarang).dp(0);
      const uang = interest.plus(sekarang).dp(0);
      const normal = new Big(this.komitmen).times(i + 1).dp(0);
      const selisih = normal.minus(uang);

      this.rows.push({
        bulan: i + 1,
        pokok: sekarang.toFormat(),
        bunga: interest.toFormat(),
        uang: uang.toFormat(),
        normal: normal.toFormat(),
        selisih: selisih.toFormat(),
      });

      sekarang = sekarang.plus(this.komitmen).plus(interest);
    }

    console.log(sekarang.toFormat());
  }
}
